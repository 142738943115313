import React,{useEffect, Suspense, lazy} from 'react'
import { Routes, Route, useLocation} from 'react-router-dom'
import './App.css';
import NavBar from './Common/NavBar';
import Footer from './Common/Footer';
import Pagenotfound from './Screen/Pagenotfound';
import Loader from './Screen/Loader';
const LazyHome = React.lazy(() => import('./Screen/Home'))
const LazyAboutandcontact = React.lazy(() => import('./Screen/About'))
const LazyProject = React.lazy(() => import('./Screen/Project'))
const LazyMore = React.lazy(() => import('./Screen/More'))
const LazyMoreproject = React.lazy(() => import('./Screen/Moreproject'))

function App() {

  const location = useLocation()
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [location])

  return (
    <div className='App'>
      <NavBar/>
        <Routes>
          <Route exact path='/' element={<Suspense fallback={<Loader />}><LazyHome /></Suspense>}/>
          <Route exact path='/aboutandcontact' element={<Suspense fallback={<Loader/>}><LazyAboutandcontact/></Suspense>}/>
          <Route exact path='/more' element={<Suspense fallback={<Loader/>}><LazyMore/></Suspense>}/>
          <Route exact path='/project/:projectname/:projectid' element={<Suspense fallback={<Loader/>}><LazyProject/></Suspense>}/>
          <Route exact path='/moreproject/:moreprojectname/:moreprojectid' element={<Suspense fallback={<Loader/>}><LazyMoreproject/></Suspense>}/>
          <Route exact path='*' element={<Pagenotfound/>}/>
        </Routes>
      <Footer/>
    </div>
  );
}

export default App;
