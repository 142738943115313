import React, {useRef, useEffect} from 'react'
import '../Css/Loader.css'
import {gsap, Elastic} from "gsap";


const Loader = () => {

  const animateRef = useRef()

  useEffect(() => {

    gsap.fromTo(
      animateRef.current,
      {opacity:0.5, ease:Elastic, duration:1, yPercent:8},
      {opacity:1, yPercent:-8,  yoyo:true, repeat:-1, },
    )

  }, [])
  

  return (
    <div id='Loader' ref={animateRef}>
        <p>Loading</p>
        <p>Tundé's</p>
        <p>data...</p>
    </div>
  )
}

export default Loader